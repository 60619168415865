<template>
<div v-loading="loading">
  <el-container v-if="pcFlag">
    <el-header>
      <div class="sider-logo">
        <img src="@/assets/images/logo.png" alt="">
        <span>订单确认</span>
      </div>
      <div class="payLogo">
        <img src="@/assets/images/Chinapay_logo.png" alt="">
      </div>
    </el-header>
    <el-main>
      <el-table border :data="tableData" class="common-table" style="width: 100%;" :height="tableHeight">
        <el-table-column type="expand">
          <template slot-scope="props" v-if="props.row.goodsDetail.length>0">
            <div class="expandBox" v-for="(item,index) in props.row.goodsDetail" :key="index">
              {{item}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="businessOrderNo" label="订单编号">
        </el-table-column>
        <el-table-column prop="goodsName" label="商品名称">
        </el-table-column>
        <el-table-column prop="goodsPrice" label="金额">
        </el-table-column>
        <el-table-column prop="goodsQuantity" label="数量">
        </el-table-column>
        <el-table-column prop="goodsTotalPrice" label="小计">
        </el-table-column>
      </el-table>
      <div class="box-card">
        <div class="text-item">
          付款方：{{orderInfo.orderCompanyName}}
        </div>
        <div class="text-item">
          商户名称：{{orderInfo.receiveName}}
        </div>
        <div class="text-item">
          支付总金额：￥{{orderInfo.orderAmount}}
        </div>
        <el-button type="primary" size="medium" @click="confirm" :loading="payLoading">确认支付</el-button>
      </div>
    </el-main>
    <el-footer>
      <p>
        Copyright© {{year}} 上海灿谷网络科技有限公司 版权所有
        <a class="icp" href="http://www.miibeian.gov.cn/" style="color: #101D36;">沪ICP备17021964号</a>
      </p>
      <p>
        <img :src="require('@/assets/images/ga.png')" style="vertical-align: middle;margin-right:3px">
        <a class="icp" style="vertical-align: middle;color: #101D36;" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502018487">沪公网安备 31011502018487号</a>
      </p>
    </el-footer>
  </el-container>
  <el-result class="resultBox" icon="warning" title="请在电脑端使用IE浏览器打开以下网址发起网银转账" v-else>
  </el-result>
  <form id="submitForm" v-show="false" name="submitForm" method="post" :action="actionUrl">
    <input :name="item.key" :value="item.value" v-for="(item,index) in inputValArr" :key="index"/>
    <input type="submit">
  </form>
</div>
</template>

<script>
import { isIE } from '@/assets/js/utils'
import {payStatusQuery,cashierOrderQuery,initiatePay} from '@/api'
export default {
  data(){
    return {
      tableData:[],
      loading:false,
      pcFlag:false,
      orderInfo:{},
      orderNo:'',
      sign:'',
      payLoading:false,
      actionUrl:'',
      inputVal:{},
      inputValArr:[],
      year:'',
      canClick:false,
      tableHeight:300
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getHeight()
    })
    window.addEventListener('resize', this.getHeight)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getHeight)
  },
  created(){
    this.pcFlag=isIE()
    var now = new Date()
    this.year = now.getFullYear()
    if(this.pcFlag){
      this.orderNo=this.$route.query.orderNo || ''
      this.sign=this.$route.query.sign || ''
      this.getPayStatus()
    }
  },
  methods:{
    getPayStatus(){
      this.loading=true
      payStatusQuery({
        orderNo:this.orderNo,
        sign:this.sign
      }).then(res=>{
        this.loading = false
        if(res.tradeState=='1'){ //已支付
          this.$router.push(`/paySuccess?orderNo=${this.orderNo}&sign=${this.sign}`)
        } else {//未支付
          this.getOrderInfo()
        } 
      }).catch(() => {
        this.loading = false
        this.getOrderInfo()
      //  this.$message.error(err)
      })
    },
    getOrderInfo(){
      this.loading=true
      cashierOrderQuery({
        orderNo:this.orderNo,
        sign:this.sign
      }).then(res=>{
        this.loading = false
        this.canClick=true
        this.orderInfo=res
        let arr=res.orderDetail || []
        // arr.map(item=>{
        //   item.orderNo=res.orderNo
        // })
        this.tableData=arr
      }).catch(err => {
        this.$message.error(err)
        this.loading = false
      })
    },
    confirm(){
      if(this.canClick){
        this.payLoading=true
        initiatePay({
          channelId:'2',
          orderNo:this.orderNo,
          sign:this.sign,
          updateTime:this.orderInfo.updateTime
        }).then(res=>{
          this.canClick=false
          this.inputVal=JSON.parse(res.param)
          this.actionUrl=res.payUrl
          for(let i in this.inputVal){
            this.inputValArr.push({
              key:[i],
              value:this.inputVal[i]
            })
          }
          this.$nextTick(()=>{
            this.payLoading = false
            document.getElementById("submitForm").submit()
          })
        }).catch(err => {
          this.canClick=true
          this.$message.error(err)
          this.payLoading = false
        })
      }
    },
    getHeight () {
      const table = document.querySelector('.common-table')
      table.style.height = window.innerHeight - 461 + 'px'
    },
  }
}
</script>

<style lang="scss" scoped>
.sider-logo{
  line-height: 60px;
  img{
    vertical-align: middle;
  }
  span{
    padding-left: 20px;
  }
}
/deep/.el-table{
  margin-top:100px;
}
/deep/.el-table th{
  background-color: #f5f6f7;
}
.el-header {
  background-color: #595767;
  line-height: 60px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  padding-left:20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .payLogo,.payLogo img{
    height: 42px;
    width: auto;
  }
}
.box-card{
  margin-top:50px;
  margin-right: 100px;
  float: right;
  font-size: 20px;
  .el-button{
    margin-top:20px;
  }
}
.expandBox{
  margin-left: 50px;
}
.resultBox{
  margin-top:20%;
}
.icp:hover{
  text-decoration: underline;
  cursor: pointer
}
.el-footer{
  font-size: 14px;
  height: 75px !important;
  padding-top:15px;
  width: 100%;
  position: fixed;
  bottom: 0px;
  text-align: center;
  background-color: #49484e;
  color: #bbb;
  z-index: 99;
  a{
    color: #bbb !important;
  }
}
</style>